import classNames from "classnames";
import { PictureLoader } from "./PictureLoader";
import { TextLoader } from "./TextLoader";

type Props = {
  type: "text" | "picture";
  height?: number;
  width?: number;
  size?: number;
  form?: "square" | "circle";
  className?: string;
};

export function Skeleton({
  type,
  height = 15,
  width,
  form = "circle",
  size = 5,
  className = ""
}: Props) {
  return (
    <div className={classNames(
      className,
      "loader skeleton-container",
      type === "text" && `h-${height}`,
      type === "text" && width && `w-${width}`,
      type === "picture" && size && `s-${size}`,
      type
    )}>
      {type === "text" && <TextLoader />}
      {type === "picture" && <PictureLoader form={form} />}
    </div>
  );
}
