"use client";
import { AiOutlineInfoCircle } from "../../../react-icons/ai";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  children: any | any[];
  iconSize?: number;
  iconColor?: string;
  className?: string;
};

export function InfoBulle({ children, iconSize = 20, iconColor = "var(--color-text)", className }: Props) {
  return (
    <Tooltip legend={children} className={className} position="bottom">
      <AiOutlineInfoCircle size={iconSize} color={iconColor} />
    </Tooltip>
  );
}
