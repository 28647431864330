import classNames from "classnames";

type Props = {
  form: "square" | "circle";
};

export function PictureLoader({ form }: Props) {
  return (
    <div className={classNames("skeleton", form)}></div>
  );
}
