import classNames from "classnames";

type Props = {
  color?: string;
  secondaryColor?: string;
  width?: string;
  strokeWidth?: number;
  className?: string;
  style?: any;
};

export function CircleLoader({
  color = "var(--color-active)",
  secondaryColor = "var(--color-gray3)",
  width = "80px",
  strokeWidth = 7,
  className = "",
  style,
}: Props) {
  return (
    <div className={classNames("loader", className)} style={style}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
        xmlSpace="preserve"
        width={width}
      >
        <circle
          className={`secondary`}
          fill="none"
          cx="50"
          cy="50"
          r="40"
          stroke={secondaryColor}
          strokeWidth={strokeWidth + "px"}
        ></circle>
        <circle
          fill="none"
          cx="50"
          cy="50"
          r="40"
          stroke={color}
          strokeWidth={strokeWidth + "px"}
          strokeDasharray={170}
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1.5s"
            from="0 50 50"
            to="360 50 50"
            values="0 50 50; 100 50 50; 260 50 50; 360 50 50"
            keyTimes="0; 0.2; 0.8; 1"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
}
