import React from "react";
import { CircleLoader } from "../circleLoader/CircleLoader";

type Props = {
  size?: number;
};

export function AbsoluteCircleLoader({ size = 200 }: Props) {
  return (
    <div
      className="tile flex j-center a-center"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backdropFilter: "blur(30px)",
        opacity: 1,
        zIndex: 10,
        width: size + "px",
        height: size + "px",
        borderRadius: "40px",
        background: "rgba(255, 255, 255, 0.2)",
      }}
    >
      <CircleLoader width="120" strokeWidth={7} />
    </div>
  );
}
